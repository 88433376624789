<!-- Finder particular de Miscelaneas -->

<template>
  <div class="miscF" v-if="loaded">

    <div style="display:flex;justify-content:center">
      <v-sheet v-if="!viewMtoOnly" :elevation="4">
      
        <!-- Cabecera -->
        <div class="cabecera">     
          <baseHeader
            :cfg="$cfe.headers.finder"
            :titulo='$store.state[storeName].titulo'
            @onEvent="eventHeader"> 
          </baseHeader>
        </div>


        <!-- Contenido -->
        <div class="contenedor" style="width:900px">

          <!-- <div v-html="info"></div> -->

          <!-- Filtro de Búsqueda -->
          <div style="width:60rem">
            <div style="display:flex">
              <uiText
                style="width:60%"              
                v-model="ct.name[2]"
                :label="ct.name[1]"
                @Enter="eventFiltro('buscar')">                
              </uiText>              

              <v-btn style="margin-left:5px" v-bind="$cfe.btn.busca" @click="eventFiltro('buscar')">
                <v-icon dark>{{ $cfe.btn.busca.icono }}</v-icon>
              </v-btn> 

              <v-checkbox
                class="ml-2"
                style="width:30%"
                v-bind="$checkbox"
                v-model="ct.bajas[2]"
                :label="ct.bajas[1]">
              </v-checkbox>

            </div>

            <div style="display:flex">

              <v-select
                style="width:35%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
                v-bind="$select"
                v-model="ct.tipo[2]"
                :label="ct.tipo[1]"
                :items="tipo_misc"
                item-value="id"
                item-text="name">                
              </v-select>

              <ctrlF
                v-if="app==1"
                style="width:35%" 
                :ct="ct.loc"> 
              </ctrlF>
              
              <ctrlF
                style="width:30%"
                :ct="ct.cia_id"> 
              </ctrlF>

            </div>
            
          </div>

          <!-- Grid -->
          <baseGridMD
            :store="storeName"
            :headers="headerGrid"
            :items-per-page="25"           
            dense
            @onEvent="execAccion">

              <!-- Slot botonera Top -->
              <template v-slot:gridTop="{}">
                <div>

                  <baseBtraMto
                    :perm="permMto"
                    :modulo="btGridCfg"
                    @onEvent="execAccion">
                  </baseBtraMto>

                </div>
              </template>
           
              <!-- Slot campo acciones del Grid -->
              <template v-slot:acciones="{ item }">
                <baseBtraMto
                  :perm="permMto"
                  :modulo="btRowCfg"
                  @onEvent="execAccion({ ...$event, ...{item:item} })">
                </baseBtraMto>
              </template>
          </baseGridMD>
        </div>
      </v-sheet>

      <!-- Mto Miscelaneas -->
      <component      
        :is="componenteDinamico"  
        :syncDisparo="syncDisparoSend"
        :syncAccion="syncAccionSend"
        syncUpdate        
        :storeRaiz="storeName"
        :masterStore="storeName"
        :ctrlCt="ctrlCt"
        :readOnly="readOnly"
        :viewMtoOnly="viewMtoOnly"
        @onEvent="$emit('onEvent', $event)">
      </component>
      
    </div>
  </div>
</template>



<script>

  import { mixF } from "@/mixins/mixF.js";
  import baseHeader from "@/base/baseHeader";
  import baseGridMD from "@/base/baseGridMD";
  import baseBtraMto from "@/base/baseBtraMto"; 
  import ctrlF from "@/components/ctrlF";
  import miscM from "@/components/miscM";

  export default {
    mixins: [mixF],
    components: { baseHeader,  baseGridMD, baseBtraMto, ctrlF, miscM },
    props: {},

    data() {
      return {  
        stIni: {
          api: "miscF",
          titulo:"Miscelanea", 
          name:"miscF",
          mView:'miscM',
          pView:[]
        },       
      
        //modal:false,       
        tipo_misc:[]      
      };
    },


    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");             
  
        this.headers = [{
          name:['buscarFuneraria', 'buscarOrigen'],
          fields:[        
            { text: "Acciones", value: "acciones", sortable: false, width: "10%", slot:true },
            { text: "Nombre", value: "name", width: "40%" },
            { text: "Tipo", value: "tipo", width: "20%" },
            { text: "Atlas", value: "atlas", width: "30%" }
          ]}
        ];

        this.headerGrid= this.headerSet();

        // array de Tipo de Miscelaneas
        this.tipo_misc= this.$store.state.datos_iniciales.misctipo;
        this.tipo_misc.unshift({ id:"0", name:"(Selecciona Tipo)"});
      }, 


    }
  };
</script>
